import React, { useState, useRef, useEffect } from 'react';
import { Container, Form, Button, Spinner } from 'react-bootstrap';
import { Ollama } from 'ollama/browser';
import './Chat.css';

const Chat = () => {
  const [messageList, setMessageList] = useState([]);
  const [input, setInput] = useState('');
  const [fetching, setFetching] = useState(false);
  const abortControllerRef = useRef(null);
  const textareaRef = useRef(null);

  const urlAddress = 'http://ai.roboteman.com'; //http://ai.roboteman.com //http://0.0.0.0
  const ollama = new Ollama({ host: urlAddress + ':11434' });
  const modelName = 'hf.co/Rezaalav1/gemma3-qa-persian';

  const isMostlyPersian = (text) => {
    const persianChars = text.match(/[\u0600-\u06FF]/g) || [];
    const totalChars = text.replace(/\s/g, '').length || 1; // حذف فاصله‌ها از محاسبه
    return persianChars.length / totalChars > 0.3; // اگه بیشتر از 30٪ فارسی بود، rtl کن
  };

  const sys_content1 = ``;
  const sys_content = `
شما دستیار فروش رسمی گروه مالی آرادفین هستید. وظیفه شما معرفی و ترغیب کاربر به تهیه اشتراک ربات معامله‌گر آرادفین است. از مزایای فنی و عملکردی ربات بگویید و اگر پاسخ دقیقی نداشتید، کاربر را به صفحه اصلی سایت Aradfin.com هدایت کنید.
`;

const assistant_content = "ربات معامله‌گر آرادفین یک سیستم هوشمند معاملاتی است که به‌طور خودکار فرصت‌های مناسب خرید و فروش را در بازار فارکس شناسایی کرده و اجرا می‌کند. این ربات‌ها بر اساس الگوریتم‌های بهینه‌شده، تحلیل تکنیکال پیشرفته، و ساختارهای اختصاصی طراحی شده‌اند تا دقت، سرعت و سودآوری معاملات شما را افزایش دهند. اگر به دنبال کاهش خطاهای انسانی، حذف تصمیم‌گیری‌های احساسی و دریافت نتایج قابل‌اتکا در بازار فارکس هستید، ربات‌های آرادفین با تست‌های بک‌تست و عملکرد واقعی، گزینه‌ای حرفه‌ای برای شما هستند. برای مشاهده نتایج تست‌ها و تهیه اشتراک، به سایت ما مراجعه کنید: Aradfin.com";

  const invisibleMessagesRef = useRef([
    {
      role: 'system',
      content: sys_content,
    },
    {
      role: 'assistant',
      content: assistant_content,
    }    
  ]);
  
  
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
    }
  }, [messageList]);

  const fetchResponse = async () => {
    try {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
  
      const abortController = new AbortController();
      abortControllerRef.current = abortController;
  
      setFetching(true);
  
      const userMessage = {
        role: 'user',
        content: input,
      };
  
      const newVisibleList = [...messageList, userMessage];
      setMessageList(newVisibleList);
  
      const assistantMessage = {
        role: 'assistant',
        content: '',
      };
      const updatedVisibleList = [...newVisibleList, assistantMessage];
      setMessageList(updatedVisibleList);
  
      // این لیست فقط به مدل داده می‌شه، نه به UI
      const fullContextForModel = [
        ...invisibleMessagesRef.current,
        ...newVisibleList,
      ];
  
      const stream = await ollama.chat({
        model: modelName,
        messages: fullContextForModel,
        options: {
          num_keep: 0,
          num_predict: 512,           // ✅ کافی برای پاسخ‌های کوتاه، سریع‌تر
          temperature: 0.1,           // ✅ کاملاً دقیق، بدون خلاقیت اضافی
          top_k: 20,                  // ✅ فقط کلمات پرتکرار مجاز
          top_p: 0.8,                 // ✅ تمرکز روی پاسخ‌های محتمل
          typical_p: 0.9,             // می‌تونه بمونه یا حذف بشه
      
          repeat_last_n: 32,
          repeat_penalty: 1.3,        // ✅ جلوگیری قوی از تکرار
      
          presence_penalty: 0.2,      // ✅ کمی خلاقیت مجاز
          frequency_penalty: 0.2,     // ✅ پاسخ یکنواخت و مختصر
      
          penalize_newline: true,     // ✅ جملات کوتاه و کمتر پاراگرافی
          stop: ["<|user|>", "<|system|>", "</s>"],  // ✅ پایان سریع‌تر پاسخ
      
          num_ctx: 2048,              // ✅ کافی برای سوال‌های ساده
          low_vram: true,
          f16_kv: true,
          use_mmap: true,
          use_mlock: false,           // اگر سیستم RAM محدودی داره، بهتر false باشه
      
          rope_frequency_base: 1.1,
          rope_frequency_scale: 0.8,
        },
        stream: true,
      });
      
  
      for await (const chunk of stream) {
        if (abortController.signal.aborted) break;
        assistantMessage.content += chunk.message.content;
        setMessageList([...newVisibleList, { ...assistantMessage }]);
  
        setTimeout(() => {
          if (textareaRef.current) {
            textareaRef.current.scrollTop = textareaRef.current.scrollHeight;
          }
        }, 0);
      }
    } catch (error) {
      if (error.name !== 'AbortError') {
        const errorMessage = {
          role: 'system',
          content: `خطا: ${error.message}`,
        };
        setMessageList([...messageList, errorMessage]);
      }
    } finally {
      setFetching(false);
    }
  };
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!input.trim()) return;
    setInput('');
    await fetchResponse();
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSubmit(event);
    }
  };

  const handleAbort = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      setFetching(false);
      console.log('The request has been aborted');
    }
  };

  const [darkMode, setDarkMode] = useState(() => {
    return localStorage.getItem('theme') === 'dark';
  });
  
  useEffect(() => {
    if (darkMode) {
      document.body.classList.add('dark-mode');
      localStorage.setItem('theme', 'dark');
    } else {
      document.body.classList.remove('dark-mode');
      localStorage.setItem('theme', 'light');
    }
  }, [darkMode]);
  
  const toggleTheme = () => {
    setDarkMode(prev => !prev);
  };  

  return (
    <Container className="chat-container" style={{ fontFamily: 'Vazir ,Ubuntu, Tahoma' }}>
      <link href="https://cdn.fontcdn.ir/Font/Persian/Vazir/Vazir.css" rel="stylesheet" type="text/css" />
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button variant="link" onClick={toggleTheme} style={{ fontSize: '22px', textDecoration: 'none' }}>
          {darkMode ? '🌞' : '🌙'}
        </Button>
      </div>

      <h1 style={{ textAlign: 'center', direction: 'rtl' }}>💻 دستیار معاملات الگوریتمی</h1>
      <div
        ref={textareaRef}
        className="chat-textarea"
      >
        {messageList.map((message, index) => {
          const isRTL = isMostlyPersian(message.content);

          return (
            <div
              key={index}
              className={message.role === 'user' ? 'message-right' : 'message-left'}
              style={{
                direction: isRTL ? 'rtl' : 'ltr',
                textAlign: isRTL ? 'right' : 'left',
                whiteSpace: 'pre-wrap'
              }}
            >
              {message.content.split('\n').map((line, i) => (
                <p key={i} style={{ marginBottom: '6px', marginTop: 0 }}>
                  {line.split(/(\*[^*]+\*)/g).map((chunk, j) => {
                    if (chunk.match(/^\*[^*]+\*$/)) {
                      return <strong key={j}>{chunk.slice(1, -1)}</strong>;
                    }
                    return <span key={j}>{chunk}</span>;
                  })}
                </p>
              ))}
            </div>
          );
        })}
        {fetching && (
          <div className="message-left">
            <em>در حال پاسخ‌دهی...</em>
          </div>
        )}
      </div>

      <Form onSubmit={handleSubmit} className="chat-form">
      <Form.Control
        as="textarea"
        rows={3}
        value={input}
        onChange={(e) => setInput(e.target.value)}
        onKeyPress={handleKeyPress}
        placeholder="پیام خود را تایپ کنید (Shift+Enter برای خط جدید)"
        className="chat-input"
        style={{
          direction: isMostlyPersian(input) ? 'rtl' : 'ltr',
          textAlign: isMostlyPersian(input) ? 'right' : 'left'
        }}
      />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button
        type="submit"
        className={`chat-button ${fetching ? 'loading' : 'idle'}`}
        disabled={fetching}
      >
        ارسال
      </Button>

      <Button
        className={`abort-button ${fetching ? 'loading' : 'idle'}`}
        onClick={handleAbort}
        disabled={!fetching}
      >
        توقف
      </Button>
        </div>
      </Form>
      <div className="sticky-footer arad-footer" style={{ direction: 'ltr' }}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <a
            href="https://aradfin.com/"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: 'inline-flex',
              alignItems: 'center',
              gap: '6px',
              textAlign: 'center'
            }}
          >
            <span>طراحی و توسعه برنامه توسط امیررضا - ۲۰۲۵ (تمامی حقوق محفوظ است)</span>
            <img
              src="https://aradfin.com/wp-content/uploads/2022/12/favicon-1.svg"
              alt="Aradfin Logo"
              style={{ height: '20px', width: '20px', borderRadius: '3px' }}
            />
          </a>
        </div>
      </div>


    </Container>
  );
};

export default Chat;
